@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $color-black;
	font-family: $font-primary;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	width: 100%;
	overflow-x: hidden;
	position: relative;
	pointer-events: all;
	// cursor: none;

	p,
	a,
	i,
	h2 {
		color: $color-sand !important;
	}

	#logo-dark {
		display: none;
	}

	&.dark {

		p,
		a,
		i,
		h2 {
			color: $color-dark-green !important;
		}

		#logo-light {
			display: none;
		}

		#logo-dark {
			display: block;
		}

		.line {
			background-color: $color-dark-green !important;
		}

		header {
			&.active {
				background-color: $color-sand !important;
			}
		}

		a.nav-btn {
			color: $color-dark-green !important;
		}
	}
}

// #cursor {
// 	width: 50px;
// 	height: 50px;
// 	background-image: url('../img/light.png');
// 	background-size: cover;
// 	position: absolute;
// 	pointer-events: none;
// 	z-index: 9999;
// 	transform: translate(-50%, -50%);
// }

a {
	// cursor: none;
	color: inherit;
	font-family: $font-primary;

	@include hover-focus {
		color: $color-white;

	}
}

.btn {
	border: 2px solid $color-dark-green;
	background-color: $color-dark-green;
	transition: all .3s ease-in-out;

	@include hover-focus {
		opacity: .6;
	}
}

header {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 5;
	padding-top: rems(58);

	&.active {
		background-color: $color-black;
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include tablet-down {
			flex-direction: column;
			// opacity: 0;
			// pointer-events: none;


			&.active {}
		}


		.wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			img.logo {
				width: 100%;
				max-width: rems(300);
			}

			ul.social {
				display: flex;
				align-items: center;
				list-style: none;
				gap: rems(10);
				padding: 0;
				margin: 0;

				li {

					a {
						transition: all .3s ease-in-out;

						@include hover-focus {
							transform: scale(1.2);
						}

						@include hover-focus {
							opacity: .6;
						}

						i {
							color: $color-dark-green;
						}
					}
				}
			}
		}

		nav#nav-menu {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: rems(10) 0;

			@include tablet-down {
				&:not(.active) {
					opacity: 0;
					pointer-events: none;
					height: 0;
					margin: 0;
				}
			}

			ul {
				list-style: none;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: rems(48);
				padding: 0;
				margin: 0;

				@include tablet-down {
					padding: rems(20);
					flex-direction: column;
				}

				li.nav-item {
					a {
						font-size: rems(20);
						text-transform: uppercase;
						color: $color-dark-green;

						@include tablet-down {
							font-size: rems(18);
						}

						@include hover-focus {
							opacity: .6;
						}
					}

					a.btn {
						font-size: rems(20);
						padding: rems(4) rems(12);
						color: $color-sand;
						border: 1px solid $color-tope;
						background-color: transparent;

						@include hover-focus {
							opacity: .6;
						}
					}
				}
			}
		}

		.mobile-menu-wrapper {
			position: absolute;
			top: rems(52);
			right: rems(10);

			.mobile-menu-icon {
				display: none;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				pointer-events: all;
				cursor: pointer;
				position: relative;
				z-index: 30;
				margin: rems(10);

				// ICON SIZE
				width: 35px;
				height: 40px;

				@include tablet-down {
					display: flex;
				}
			}

			.line {
				width: 100%;
				height: 2px;
				background-color: $color-sand;
				margin: 4.5px;
				transition: transform 0.3s ease;
				transform-origin: center;


				&.active {
					transform: rotate(45deg) !important;
					position: absolute;
					top: 30%;
					background-color: $color-clay;

					&.active:nth-child(2) {
						display: none;
					}

					&.active:nth-child(3) {
						transform: rotate(-45deg) !important;
					}
				}
			}
		}
	}
}

main {
	position: relative;
	min-height: calc(100vh - 30px);
	overflow-y: scroll;

	section {
		width: 100%;
		display: none;
		position: relative;

		.section-bg {
			position: fixed;
			object-fit: cover;
			object-position: center;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			pointer-events: none;
		}
	}

	section#hero {
		height: 100vh;

		&.active {
			display: block;
		}

		.section-bg {
			object-position: center top;
		}

		.container {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			height: 100vh;

			@include tablet-down {
				align-items: flex-end;
				justify-content: center;
			}

			.hero-content {
				text-align: center;
				text-transform: uppercase;
				padding: 0 0 0 rems(20);
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				@include tablet-down {
					margin: 0 0 rems(300) 0;
					flex-direction: column;
					justify-content: center;
				}

				.content-wrapper {
					margin-right: rems(100);

					@include tablet-down {
						margin: 0;
					}
				}

				p {
					margin-bottom: rems(32);
				}

				a.btn {
					font-size: rems(20);
					padding: rems(4) rems(12);
					color: $color-sand !important;
					border: 1px solid $color-tope;
					background-color: transparent;

					@include hover-focus {
						opacity: .6;
					}
				}
			}
		}

		.wrapper {
			width: 100%;
			position: relative;

			video {
				position: fixed;
				z-index: 2;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: bottom;
			}
		}
	}

	section#tour {
		padding: rems(200) 0 rems(200);
		background-color: $color-sand;
		position: relative;
		// background-image: url(../img/bg/bg-main.png);
		// background-position: fixed;
		// background-size: cover;
		// background-repeat: no-repeat;


		&.active {
			display: block;
		}

		.section-bg {
			z-index: 2;
			opacity: .16;
		}

		.container {
			#tour-dates {
				.event-group {
					display: flex;
					gap: rems(16);
					margin: rems(24) 0;
					font-family: $font-primary;
					font-size: rems(20);

					@include tablet-down {
						flex-direction: column;
						text-align: center;
						margin-bottom: rems(40);
					}

					.event-date-venue-group {
						flex: 1.4;
						text-transform: capitalize;

						.event-date {
							text-transform: capitalize;
						}

						.event-venue {
							text-transform: capitalize;
						}
					}

					.event-location {
						display: flex;
						align-items: center;
						flex: 1;
						text-transform: capitalize;

						@include tablet-down {
							justify-content: center;
						}
					}

					.event-links {
						flex: 1;
						display: flex;
						flex-direction: row-reverse;
						align-items: center;
						gap: rems(10);

						@include tablet-down {
							justify-content: center;
						}

						a.btn {
							padding: rems(8) rems(20);
							letter-spacing: 1px;
							color: $color-sand !important;
							border: 1px solid $color-dark-green;

							@include hover-focus {
								opacity: .6;
							}
						}
					}
				}
			}
		}
	}

	section#music {
		padding: rems(200) 0 rems(200);
		// background-image: url(../img/bg/bg-main.png);
		// background-position: top center;
		// background-size: cover;
		// background-repeat: no-repeat;

		&.active {
			display: block;
		}

		.container {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: rems(50);

			@include tablet-down {
				flex-direction: column;
			}

			.item {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				gap: rems(20);
				max-width: rems(525);
				width: 100%;

				.cover {
					width: 100%;
					flex: 1;
					position: relative;

					img {
						width: 100%;
					}

					a.hover-overlay-cover {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: $color-video-overlay;
						transition: all .4s ease-in-out;
						opacity: 0;

						@include hover-focus {
							opacity: 1;
						}

						h2 {
							font-size: rems(20);
							font-family: $font-primary;
							font-weight: 400;
							pointer-events: none;
							text-align: center;
						}
					}
				}

				.info {
					flex: 1;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					text-transform: uppercase;
					display: flex;
					text-align: center;

					h2 {
						font-size: rems(28);
						font-weight: 100;
						letter-spacing: 1px;
						text-align: center;
						display: none;

						@include tablet-down {
							display: block;
						}
					}

					.img-title {
						padding: 0 rems(20);
						margin-bottom: rems(10);
						display: none;

						@include tablet-down {
							display: block;
						}
					}

					p {
						font-size: rems(18);
						text-align: center;
						display: none;

						@include tablet-down {
							display: block;
						}

						span {
							font-family: $font-primary;
						}
					}

					a.btn {
						font-size: rems(20);
						padding: rems(12) rems(20);
						border: 1px solid $color-tope;
						background-color: transparent;

					}
				}
			}
		}
	}

	section#video {
		padding-top: rems(200);
		// background-image: url(../img/bg/bg-main.png);
		// background-position: top center;
		// background-size: cover;
		// background-repeat: no-repeat;

		&.active {
			display: block;
		}

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			max-width: rems(700);

			.item {
				width: 100%;
				margin-bottom: rems(80);

				.embed-container {
					iframe {}

					img {}

					.hover-overlay {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: $color-video-overlay;
						transition: all .4s ease-in-out;
						opacity: 0;

						@include hover-focus {
							opacity: 1;
						}

						h2 {
							font-size: rems(20);
							font-family: $font-primary;
							font-weight: 400;
							pointer-events: none;
							// text-transform: uppercase;
						}
					}

					.hover-overlay,
					.hover-overlay-image {
						@include tablet-down {
							display: none;
						}
					}
				}
			}

			a.btn {
				padding: rems(8) rems(30);
				font-size: rems(28);
				margin-bottom: rems(100);
				text-transform: uppercase;
				border: 1px solid $color-tope;
				background-color: transparent;

				@include hover-focus {
					opacity: .6;
				}
			}
		}
	}

	section#signup {
		padding: rems(300) 0 rems(200);
		min-height: 100vh;
		background-color: $color-sand;
		position: relative;
		// background-image: url(../img/bg/bg-main.png);
		// background-position: top center;
		// background-size: cover;
		// background-repeat: no-repeat;

		@include tablet-down {
			padding-top: rems(350);
		}

		&.active {
			display: block;
		}

		.section-bg {
			z-index: 2;
			object-position: 25% 50%;
			object-fit: cover;

			@include tablet-up {
				object-position: 50% 50%;
			}

			@include desktop-sm-up {
				object-fit: cover;
				object-position: 80% 50%;
			}
		}

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			z-index: 3;

			h2 {
				font-family: $font-primary;
				color: $color-dark-green;
				font-size: rems(28);
				text-transform: uppercase;
				font-weight: 100;
				letter-spacing: 1px;
			}

			form#kameron-marlowe-website-newsletter-signup {
				margin-bottom: rems(100);
				display: flex;
				align-items: flex-end;
				position: relative;

				@include tablet-down {
					flex-direction: column;
					gap: rems(30);
					align-items: center;
				}

				input[type="text"] {
					background-color: transparent;
					border: none;
					border-bottom: 2px solid $color-caramel;
					color: $color-almond;
					width: rems(450);
					font-size: rems(20);
					padding: rems(8) 0;
					text-transform: uppercase;
					letter-spacing: 1px;
					font-family: $font-primary;

					&::placeholder {
						color: $color-dark-green;
					}

					&:focus {
						outline: none;
					}
				}

				button[type="submit"] {
					padding: rems(5) rems(13);
					font-family: $font-primary;
					font-size: rems(20);
					color: $color-sand;
					background-color: $color-dark-green;
					border: 1px solid $color-dark-green;
					transition: all .3s ease-in-out;
					text-transform: uppercase;
					position: absolute;
					left: 105%;
					width: 100%;
					max-width: 100px;
					text-align: center;
					white-space: nowrap;

					@include tablet-down {
						position: static;
					}

					@include hover-focus {
						opacity: .6;
					}
				}

				.thanks {
					font-size: rems(28);
					letter-spacing: 1px;
					font-weight: rems(300);
				}
			}


			a.btn {
				padding: rems(8) rems(20);
				background-color: $color-dark-green;
				font-family: $font-primary;
				font-size: rems(20);
				color: $color-sand !important;
				transition: all .3s ease-in-out;
				text-transform: uppercase;

				@include hover-focus {
					opacity: .6;
				}
			}
		}
	}
}

.footer {
	text-align: center;
	font-family: sans-serif;
	font-size: rems(9);
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	opacity: .7;
	z-index: 5;

	a {
		font-family: sans-serif;
		font-size: rems(10);

		@include hover-focus {
			color: $color-clay;
		}
	}
}

.hidden {
	pointer-events: none !important;
	opacity: 0 !important;
}