$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #121212;
$color-almond: #E8DDCF;
$color-clay: #8E5F37;
$color-grey: #707070;
$color-sand: #E8DDCF;
$color-dark-green: #2D3728;
$color-caramel: #8E5F37;
$color-tope: #C69369;
$color-video-overlay: #211A17cc;


$font-primary: "the-seasons", serif;

